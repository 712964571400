import {
    OrganicEventSearchApiPayload,
    OrganicEventSearchApiResponse,
    OrganicEventSearchFiltersApiPayload,
    searchOrganicEvents,
} from '../../../api/searchOrganicEvents';

//TODO: Update types in this file to leverage ENUMS instead of string literals (can leverage constants in code etc)
type ExpansionBody = {
    'expand.destination_event'?: string[];
    'expand.destination_profile'?: string[];
};

const EXPANSIONS: ExpansionBody = {
    'expand.destination_event': [
        'primary_venue',
        'image',
        'ticket_availability',
        'saves',
        'event_sales_status',
        'primary_organizer',
        'public_collections',
    ],
    'expand.destination_profile': ['image'],
};

export const search = (
    eventSearch: OrganicEventSearchFiltersApiPayload,
    expansions = EXPANSIONS,
): Promise<OrganicEventSearchApiResponse> => {
    const filters: OrganicEventSearchApiPayload = {
        browse_surface: 'category_browse',
        event_search: eventSearch,
        ...expansions,
    };

    return searchOrganicEvents(filters);
};
