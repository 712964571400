import React from 'react';

export const useLocationContext = () => {
    const value = React.useContext(LocationContext);

    if (value === undefined) {
        throw new Error(
            'useLocationContext must be used within LocationProvider',
        );
    }

    return value;
};

export const LocationContext = React.createContext({
    locationSlug: '',
});

export const LocationProvider: React.FunctionComponent<{
    locationSlug: string;
}> = (props) => {
    const [value, setValue] = React.useState({
        locationSlug: props.locationSlug,
    });

    React.useEffect(() => {
        setValue({ locationSlug: props.locationSlug });
    }, [props.locationSlug]);

    return (
        <LocationContext.Provider value={value}>
            {props.children}
        </LocationContext.Provider>
    );
};

export default LocationProvider;
