import {
    FormattedEvent,
    transformDestinationEvent,
} from '@eventbrite/event-renderer';
import {
    useIsFetching,
    useQuery,
    useQueryClient,
    UseQueryOptions,
} from '@tanstack/react-query';
import React from 'react';
import {
    OrganicEventSearchApiResponse,
    OrganicEventSearchFiltersApiPayload,
} from '../../../../api/searchOrganicEvents';
import { search } from '../../api';
import { useSearchContext } from '../../context';
import { useLocation } from './location';
import {
    CategoryBrowseEventsQueryKey,
    getCategoryBrowseEventsQueryKey,
    ScreenTag,
} from './utils';

export const transformResponse = (
    data: OrganicEventSearchApiResponse,
): SearchResponseData => {
    return {
        events: data.events.results.map(transformDestinationEvent),
        eventSearch: data.event_search as any,
    };
};

interface SearchResponseData {
    events: FormattedEvent[];
    eventSearch: OrganicEventSearchFiltersApiPayload;
}

interface BaseEventsQueryOptions extends UseQueryOptions {
    /**
     * Boolean to control if provided
     * hook should be involved in deduping
     * regardless of experiment
     * ie. For getting organizers to follow IDs
     */
    optOutDedupeEvents?: boolean;
}

export function useBaseSearchQuery(
    queryOverload: OrganicEventSearchFiltersApiPayload = {},
): OrganicEventSearchFiltersApiPayload {
    const { data: location } = useLocation();
    const { query } = useSearchContext();

    return {
        ...query,
        places: location?.placeId ? [location?.placeId] : undefined,
        online_events_only: location?.isOnline ? location?.isOnline : undefined,
        ...queryOverload,
    };
}

/**
 * The base query for interacting with the search API.
 *
 * It is recommended to create new functions that extend
 * the functionality of this hook to generate additional
 * useful calls.
 *
 * @param searchQuery
 * @param selector
 * @returns
 */
export function useBaseEventsQuery<ReturnType>(
    selector: (data: OrganicEventSearchApiResponse) => ReturnType,
    queryOverload: OrganicEventSearchFiltersApiPayload = {},
    queryOptions: BaseEventsQueryOptions = {},
) {
    const { data: location } = useLocation();
    const overloadedQuery = useBaseSearchQuery(queryOverload);
    const queryKey = getCategoryBrowseEventsQueryKey(
        overloadedQuery,
        queryOptions.category,
        queryOptions.screen as ScreenTag,
    );

    //If an events query is already fetching, hold off
    //on fetching until response is returned. This
    //allows subsequent calls to ensure no repeat events
    const totalEventQueriesFetching = useIsFetching(queryKey.slice(0, 3));

    const shouldEnable = React.useRef(false);
    if (!shouldEnable.current && !queryOptions.optOutDedupeEvents) {
        shouldEnable.current = totalEventQueriesFetching === 0;
    } else {
        shouldEnable.current = true;
    }

    const enabled =
        !!location &&
        shouldEnable.current &&
        (queryOptions.enabled === undefined ? true : !!queryOptions.enabled);

    const { idsToExclude } = useGetIdsToDedupe({
        queryKey: queryKey,
        enabled: enabled && !queryOptions.optOutDedupeEvents,
    });

    const overloadedQueryWithIds = {
        ...overloadedQuery,
        exclude: { ids: idsToExclude },
    };

    const response = useQuery<
        OrganicEventSearchApiResponse,
        Error,
        ReturnType,
        CategoryBrowseEventsQueryKey
    >(queryKey, async () => await search(overloadedQueryWithIds), {
        //React query is complaining that the selector
        //is the provided util or undefined as opposed to
        //a function definition of `unknown`. Ignoring for now
        //eslint-disable-next-line
        //@ts-ignore
        select: selector,
        meta:
            idsToExclude.length > 0 ? { dedupedIds: idsToExclude } : undefined,
        ...queryOptions,
        enabled,
        staleTime: 120000, // Data will be considered fresh for 2 minutes
        cacheTime: 120000, // Data will remain in cache for 2 minutes
    });

    return response;
}

interface BaseEventsQueryOptions extends UseQueryOptions {
    queryOverload?: OrganicEventSearchFiltersApiPayload;
    optOutDedupeEvents?: boolean;
    category?: string;
    screen?: ScreenTag;
}

export const useBaseEventsQueryEvents = (options?: BaseEventsQueryOptions) =>
    useBaseEventsQuery<SearchResponseData>(
        transformResponse,
        options?.queryOverload,
        options,
    );

interface UseGetIdsToDedupe {
    queryKey: CategoryBrowseEventsQueryKey;
    enabled: boolean;
}
/**
 *
 * Proposed approach once getQueryData can accept a filter:
 *  • store the id's the are deduped as a `meta` key on that particular query
 *  • check if current query exists in the cache
 *      • yes: return the data tied to meta
 *      • no: generate a list of all events on the page by using getQueries with a
 *            filter passed in of ['category-browse', 'events']
 *
 * @param baseQuery The base event query that determines aggs and Popular Events
 * @param overloadedQuery Overloaded version of the query that populates a given bucket
 * @returns
 */
const useGetIdsToDedupe = ({
    enabled,
    queryKey,
}: UseGetIdsToDedupe): { idsToExclude: string[] } => {
    const queryClient = useQueryClient();
    const queryCache = queryClient.getQueryCache();

    if (!enabled) {
        return { idsToExclude: [] };
    }

    //this should only match one query at a time
    const matchingQuery =
        queryCache.find<OrganicEventSearchApiResponse>(queryKey);

    if (matchingQuery?.meta?.dedupedIds) {
        const ids: string[] =
            (matchingQuery?.meta?.dedupedIds as string[]) || [];
        return { idsToExclude: ids };
    }

    const idSet = new Set<string>();

    //Ensure we only fetch cached queries that belong to this tag
    const queries = queryClient.getQueriesData<OrganicEventSearchApiResponse>(
        queryKey.slice(0, 4),
    );

    //iterate over each query. If it has data pass to util
    //generate list
    for (const query of queries) {
        const queryData = query[1];

        if (queryData) {
            const ids = getBaseEventsQueryEventIdsToExclude(queryData);
            ids.forEach((id) => idSet.add(id));
        }
    }

    return { idsToExclude: [...idSet] };
};

const getBaseEventsQueryEventIdsToExclude = (
    data: OrganicEventSearchApiResponse,
): Array<string> => {
    return data?.events.results.map((event) =>
        event.series_id ? event.series_id : event.id,
    );
};
