import { OrganicEventSearchFiltersApiPayload } from '../../../../api/searchOrganicEvents';
import { INITIAL_QUERY } from '../../../../context/search';

function getTagWithSubcategoryPreference(
    tags: OrganicEventSearchFiltersApiPayload['tags'] = [''],
) {
    const subcategoryTag = tags.find((tag: string) =>
        tag.includes('EventbriteSubCategory'),
    );

    if (subcategoryTag) return subcategoryTag;

    return (
        tags.find((tag: string) => tag.includes('EventbriteCategory')) || 'tag'
    );
}

type ActiveTag = string | 'tag';
type LocationTag = string | 'online' | 'location';
export type ScreenTag = string | 'category-browse' | 'nightlife' | 'dating';

export type CategoryBrowseEventsQueryKey = [
    ScreenTag,
    'events',
    ActiveTag,
    LocationTag,
    OrganicEventSearchFiltersApiPayload,
];

export function getCategoryBrowseEventsQueryKey(
    eventSearch: OrganicEventSearchFiltersApiPayload,
    category?: string,
    screen?: ScreenTag,
): CategoryBrowseEventsQueryKey {
    const normalizedEventSearch: OrganicEventSearchFiltersApiPayload = {
        ...INITIAL_QUERY,
        ...eventSearch,
        dates: eventSearch
            ? typeof eventSearch.dates === 'string'
                ? [eventSearch.dates]
                : eventSearch.dates
            : [],
    };

    const locationTag = normalizedEventSearch.online_events_only
        ? 'online'
        : eventSearch?.places?.[0] || 'location';
    const activeTag =
        category ||
        getTagWithSubcategoryPreference(normalizedEventSearch?.tags);

    return [
        screen ?? 'category-browse',
        'events',
        activeTag,
        locationTag,
        normalizedEventSearch,
    ];
}
