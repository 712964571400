import React, { useEffect } from 'react';
import { OrganicEventSearchFiltersApiPayload } from '../api/searchOrganicEvents';

export const INITIAL_QUERY: OrganicEventSearchFiltersApiPayload = {
    page_size: 8,
    exclude: { ids: [] },
    aggs: {
        tags: { size: 50 },
        organizertagsautocomplete_agg: { size: 50 },
        dates: {},
    },
    dates: ['current_future'],
};

interface SearchProviderShape {
    query: OrganicEventSearchFiltersApiPayload;
    setQuery?: (newQuery: OrganicEventSearchFiltersApiPayload) => void;
}

export const useSearchContext = () => {
    const value = React.useContext(SearchContext);

    if (value === undefined) {
        throw new Error('useSearchContext must be used within SearchProvider');
    }

    return value;
};

const SearchContext = React.createContext<SearchProviderShape>({
    query: INITIAL_QUERY,
});

export const SearchProvider: React.FunctionComponent<{
    initialQuery: OrganicEventSearchFiltersApiPayload;
}> = (props) => {
    const { initialQuery } = props;
    const [query, setQuery] =
        React.useState<OrganicEventSearchFiltersApiPayload>(
            props.initialQuery
                ? { ...INITIAL_QUERY, ...props.initialQuery }
                : INITIAL_QUERY,
        );

    const handleSetQuery = React.useCallback(
        (newQuery: OrganicEventSearchFiltersApiPayload) => {
            setQuery((currentQuery) => ({ ...currentQuery, ...newQuery }));
        },
        [],
    );

    useEffect(() => {
        handleSetQuery(initialQuery);
    }, [initialQuery, handleSetQuery]);

    return (
        <SearchContext.Provider value={{ query, setQuery: handleSetQuery }}>
            {props.children}
        </SearchContext.Provider>
    );
};

export default SearchProvider;
