import { sdkRequest } from '@eventbrite/http';
import { getOnlineEventsOption } from '@eventbrite/location-autocomplete';

export interface FormattedLocationResponse {
    currentPlace?: string;
    currentPlaceParent?: string;
    latitude?: number;
    longitude?: number;
    slug?: string;
    placeType?: string;
    placeId?: string;
    isOnline?: boolean;
    country?: string;
    countrySlug?: string;
    city?: string;
    citySlug?: string;
    borough?: string;
    boroughSlug?: string;
}

export interface LocationResponse {
    place: {
        derived_ancestor_type:
            | 'country'
            | 'region'
            | 'continent'
            | 'locality'
            | 'borough';
        name: string;
        country_iso_code: string;
        longitude: number;
        parent_id: string;
        place_type: string;
        latitude: number;
        localizations: {
            default: {
                country?: {
                    place_id: string | null;
                    location_slug: string | null;
                    suggested_abbreviation: string;
                    full_name: string | null;
                } | null;
                region?: {
                    place_id: string | null;
                    location_slug: string | null;
                    suggested_abbreviation: string;
                    full_name: string | null;
                } | null;
                continent?: {
                    place_id: string | null;
                    location_slug: string | null;
                    suggested_abbreviation: string | null;
                    full_name: string | null;
                } | null;
                locality?: {
                    place_id: string | null;
                    location_slug: string | null;
                    suggested_abbreviation: string | null;
                    full_name: string | null;
                } | null;
                neighbourhood?: {
                    place_id: string | null;
                    location_slug: string | null;
                    suggested_abbreviation: string | null;
                    full_name: string | null;
                } | null;
                borough?: {
                    place_id: string | null;
                    location_slug: string | null;
                    suggested_abbreviation: string | null;
                    full_name: string | null;
                } | null;
            };
            en_US: {
                country?: {
                    place_id: string | null;
                    location_slug: string | null;
                    suggested_abbreviation: string;
                    full_name: string | null;
                } | null;
                region?: {
                    place_id: string | null;
                    location_slug: string | null;
                    suggested_abbreviation: string;
                    full_name: string | null;
                } | null;
                continent?: {
                    place_id: string | null;
                    location_slug: string | null;
                    suggested_abbreviation: string | null;
                    full_name: string | null;
                } | null;
                locality?: {
                    place_id: string | null;
                    location_slug: string | null;
                    suggested_abbreviation: string | null;
                    full_name: string | null;
                } | null;
                neighbourhood?: {
                    place_id: string | null;
                    location_slug: string | null;
                    suggested_abbreviation: string | null;
                    full_name: string | null;
                } | null;
                borough?: {
                    place_id: string | null;
                    location_slug: string | null;
                    suggested_abbreviation: string | null;
                    full_name: string | null;
                } | null;
            };
        };
        location_slug: string;
        id: string;
        region_iso_code: string | null;
    };
}

const formatLocation = (
    location: LocationResponse,
): FormattedLocationResponse => {
    const defaultLocale = location?.place?.localizations?.default;

    return {
        currentPlace: location.place.name,
        currentPlaceParent:
            defaultLocale?.[location.place.derived_ancestor_type]
                ?.suggested_abbreviation || undefined,
        latitude: location.place.latitude,
        longitude: location.place.longitude,
        slug: location.place.location_slug,
        placeType: location.place.place_type,
        placeId: location.place.id,
        isOnline: false,
        country: defaultLocale?.country?.full_name || undefined,
        countrySlug: defaultLocale?.country?.location_slug || undefined,
        city: defaultLocale?.locality?.full_name || undefined,
        citySlug: defaultLocale?.locality?.location_slug || undefined,
        borough: defaultLocale?.borough?.full_name || undefined,
        boroughSlug: defaultLocale?.borough?.location_slug || undefined,
    };
};

const PLACE_BY_LOCATION_SLUG_URL = '/api/v3/geo/place_from_location_slug/';
export const getPlaceFromLocationSlug = (
    locationSlug: string,
): Promise<FormattedLocationResponse> => {
    if (locationSlug === 'online') {
        const onlineEventShape: FormattedLocationResponse =
            getOnlineEventsOption();
        return Promise.resolve(onlineEventShape);
    }

    return sdkRequest(
        `${PLACE_BY_LOCATION_SLUG_URL}?location_slug=${locationSlug}`,
    ).then((locationResponse: LocationResponse) =>
        formatLocation(locationResponse),
    );
};
